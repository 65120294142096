@import '../../assets/styles/variables';
@import '../../assets//styles/mixins';

.input {
  &-wrapper {
    margin-bottom: 19px !important ;
  }

  &-field {
    width: 100%;
    height: 60px;
    font-size: 30px;
    font-weight: 400;
    line-height: 20px;
    padding: 8px 16px;
    border: 1px solid #a5a5a5;

    @include window-desktop {
      height: 60px;
      font-size: 22px; 
    }
    @include tablet{
    height: 50px;
    font-size: 18px;
    }
    @include mobile{
    height: 40px;
    font-size: 18px;
    }
    @include iphone5 {
      height: 36px;
      font-size: 12px;
    }
    @include mobile-extra-small {
      height: 32px;
      font-size: 12px;
      padding: 4px 8px;
    }
  }

  // &-label {
  //   font-size: 14px;
  //   font-weight: 600;
  //   line-height: 18px;
  //   margin-bottom: 4px;
  //   color: $blue-primary;
  // }

  &-error{
    border: 1px solid red ;
  }
}


@import "../../assets/styles/variables";
@import "../../assets//styles/mixins";

.landing {

  height: 100%;

  @include mobile {
    height: calc(100vh - 80px);
  }

  @include mobile-extra-small{
    height: calc(100vh - 80px);
  }

  &-content {
    height: 100%;
    .display-content {
      display: flex;

      @include tablet {
        display: none !important;
      }
      @include mobile {
        display: none !important;
      }
      @include mobile-extra-small {
        display: none !important;
      }

      @media screen and (min-width: 641px) and (max-width: 767px) {
        display: none !important;
      }
    }

    .wrap {
      .error {
        color: red;
        justify-self: flex-start;
        font-size: 14px;
        font-weight: bold;
        align-self: start;
        margin-bottom: 1rem;
        text-align: start;
        @include mobile {
          font-size: 12px;
        }
        @include mobile-extra-small {
          font-size: 12px;
        }
      }
    }
    &-wrapper {
      background-color: $theme-primary-dark;
      // width: 40vw;
      // height: 79%;

      &-texts {
        height: 100%;
        &-wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 16px;
          .content-height {
            // margin-top: 24px;
            // background-color: orange !importa  nt;
            margin-bottom: 60px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
          }
          .card-width {
            width: 77%;
          }
        }
        &-title {
          color: #86a5d9;
          font-size: 16px;
          margin-top: 1rem;
          margin-bottom: 0;
        }
        &-quotes {
          height: 1rem;
        }
        &-body {
          color: black;
          font-size: 16px;
          line-height: 22px;
        }
      }
      &-proActive {
        height: 4rem;
        width: 75%;
        @include mobile {
          height: 4rem;
        }
      }
      &-forHertext {
        height: 1.5rem;
      }
      &-dash {
        height: 10px;
        width: 27%;
        @include tablet {
          width: 22%;
        }
      }

      &-image {
        height: 80%;
        @include tablet {
          height: 65%;
        }
        .image-set {
          height: 100%;
          object-fit: fill;
          background-color: $theme-primary-dark;
          width: 100%;
        }
      }
    }
  }

  &-information-wrapper {
    background-color: $theme-primary;
    // width: 100vw;
    height: 100%;
    padding: 0rem 3rem;
    // padding-top: 3rem;
    justify-content: flex-start;

    @include mobile {
      justify-content: flex-start;
      padding: 1.5rem 3rem;
    }

    @include mobile-extra-small {
      justify-content: flex-start;
      padding: .75rem 3rem;
    }
    @include tablet {
      justify-content: center;
    }

    .checkbox-margin{
      margin-bottom: 1.9rem;
    }

    .logo-mobile {
      display: none;
      position: fixed;
      top: 0;
      left: 3rem;
      // margin-top: 1.5rem;

      @include tablet {
        display: block;
      }
      @include mobile {
        display: block;
        text-align: left;
        padding: 0rem;
      }
      @include mobile-extra-small {
        display: block;
        text-align: left;
        padding: 0rem;
      }
      &-proActiveLogo {
        height: 2.5rem;
        @include mobile-extra-small {
          height: 1.8rem;
        }
      }
      &-dash {
        height: 0.45rem;
        width: 24%;
        @include mobile-extra-small {
          height: 0.35rem;
          width: 21%;
        }
      }
      &-forHertext {
        height: 0.6rem;
        @include mobile-extra-small {
          height: 0.5rem;
        }
      }
    }

    .padding-set {
      padding: 0rem 3rem;
      // @include tablet {
      //   padding: 0rem;
      // }
      @include mobile {
        padding: 0rem;
      }
      @include mobile-extra-small {
        padding: 0rem;
      }
    }
    .align-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-top: 75px;

      // @include large-custom-desktop {
      //   margin-top: 3.5rem;
      // }

      @include tablet {
        
        margin-top: 0;
      }

      @include mobile {
        align-items: center;
        margin-top: .8rem;
      }

      @include mobile-extra-small {
        align-items: center;
        margin-top: .8rem;
      }
    }
    &-title {
      font-size: 36px;
      line-height: normal;
      font-family: "Open Sans";
      @include tablet {
        font-size: 32px;
        // line-height: 20px;
      }
      @include mobile {
        font-size: 1.55rem;
        // line-height: 20px;
      }
      @include mobile-extra-small {
        font-size: 1.35rem;
        // line-height: 20px;
      }
      @media (min-width: 1025px) and (max-width: 1440px) {
        text-align: left;
      }
    }

    .input-extension {
      background-color: $white;
      border-right: none;
    }
    &-checkbox {
      margin-top: 7px;
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 0px;
      @include tablet {
        height: 1.5rem;
        width: 3rem;
      }
      @include mobile {
        height: 1.5rem;
        margin-top: 0px;
        width: 4rem;
      }
      @include mobile-extra-small {
        height: 1rem;
        width: 3rem;
        margin-top: 0px;
      }
      @include window-desktop {
        height: 1.5rem;
        width: 1.5rem;
        margin-top: 0px;
      }
    }
    &-subtext {
      font-size: 18px;
      line-height: 20px;
      text-align: left;
      color: rgba(34, 34, 34, 0.6);

      @include window-desktop {
        font-size: 18px;
        line-height: 24px;
      }
      @include mobile {
        font-size: 18px;
        line-height: 20px;
        text-align: center;
      }
      @include mobile-extra-small {
        font-size: 16px;
        line-height: 18px;
        text-align: center;
      }
    }
    &-subtext1 {
      font-size: 18px;
      line-height: 33px;
      font-weight: 400px;
      color: rgba(0, 0, 0, 0.6);
      @include window-desktop {
        font-size: 18px;
        line-height: 24px;
      }
      @include mobile {
        font-size: 18px;
        line-height: 20px;
      }
      @include iphone5 {
        font-size: 18px;
        line-height: 20px;
      }

      @include mobile-extra-small {
        font-size: 16px;
        line-height: 18px;
      }
      &-text {
        font-size: 18px;
        line-height: 26px;
        font-weight: 400px;
        color: rgba(0, 0, 0, 0.6);
        @include mobile{
          width: 250px
        }
        @include mobile-extra-small{
          width: 250px
        }
      }
      &-underline {
        text-decoration: underline;
        color: #555353;
      }
      &-underline:focus {
        text-decoration: underline;
        color: #555353;
      }
    }
    .link-to-authpages {
      margin-top: 2.8rem;
      // position: absolute;
      // top: 85%;
      @include mobile {
        // margin: ;
        top: 85%;
        align-items: center;
        justify-content: center;
        display: flex;
      }
      @include mobile-extra-small {
        margin-top: 1.25rem;
        top: 85%;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
  }

  .text-btn-right {
    text-align: right;

    @include mobile {
      margin-top: 2rem;
      text-align: center;
    }
    @include mobile-extra-small {
      margin-top: 2rem;
      text-align: center;
    }
  }
  .text-btn-right-signup {
    text-align: right;

    @include mobile {
      margin-top: 1rem;
      text-align: center;
    }
    @include mobile-extra-small {
      margin-top: .5rem;
      text-align: center;
    }
  }
}

// Dropdown input styles
.dropdown-input-style {
  position: relative;

  .custom-dropdown-style {
    position: absolute;
    max-width: 110px;

    @include mobile {
      max-width: 70px;
    }
    @include mobile-extra-small {
      max-width: 60px;
    }
  }

  .input-wrapper {
    margin-left: 110px;

    @include mobile {
      margin-left: 70px;
    }
    @include mobile-extra-small {
      margin-left: 60px;
    }

    .input-field {
      border-left: 0 !important;
    }
  }
}

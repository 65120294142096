// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$white: white;
$gray-light: #c4c4c4;
$gray-a: #a5a5a5;
$gray-e: #e5e5e5;
$gray-medium: #8a8a8a;
$gray-photo-back: #f2f2f2;
// Color Shades (Theme Based - Xclusive)
$theme-primary: #f9efea;
$theme-blue-primary: #42516d;
$theme-primary-dark: #42516d;
$theme-pink: #ebe5d5;
$theme-light: #86a5d9;
$theme-secondary: #122839;
$theme-success: #6bbea6;
$theme-dark-gray: #42516d;
$theme-primary-light: #f9efea;
$theme-orange: #e7bfad;
$theme-tag: #e8cf8b;
$theme-light-blue: #acb9d8;
$theme-light-orange: #f7ede4;
$theme-golden-text: #eae1c4;
//loader
$peach-primary: #e7bfad;
$peach-secondary: #f9eeea;
$theme-red: #d68477;
$theme-red-light: #f7e6e4;

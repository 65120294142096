@import "../../assets//styles/variables";
@import "../../assets//styles/mixins";

.button {
  &-primary {
    color: $white;
    height: 50px;
    font-size: 16px;
    min-width: 220px;
    font-weight: 600;
    line-height: 20px;
    border: none;
    padding: 0.5rem 1rem;
    text-transform: capitalize;
    background-color: $theme-blue-primary;

    .button-redirect{
      text-decoration: none !important;
    }
    @include window-desktop {
      font-size: 18px;
      line-height: 20px;
    }
    @include mobile {
      color: $white;
      font-size: 18px;
      min-width: 180px;
      font-weight: 600;
      line-height: 20px;
      height: 36px;
      border: none;
      padding: 0.5rem 1rem;
      text-transform: capitalize;
      background-color: $theme-blue-primary;
    }
    @include mobile-extra-small {
      color: $white;
      font-size: 16px;
      min-width: 180px;
      font-weight: 600;
      height: 36px;
      line-height: 18px;
      border: none;
      padding: 0.5rem 1rem;
      text-transform: capitalize;
      background-color: $theme-blue-primary;
    }
  }
  &-disabled {
    color: #c4c4c4;
    font-size: 16px;
    min-width: 220px;
    height: 50px;
    font-weight: 600;
    line-height: 20px;
    // cursor: none;
    border: none;
    padding: 0.5rem 1rem;
    background-color: #e5e5e5;
    text-transform: capitalize;

    @include mobile {
      color: #585151;
      font-size: 18px;
      line-height: 20px;
      height: 36px;
      background-color: #e5e5e5;
      text-transform: capitalize;
      cursor: none;
      border: none;
      min-width: 180px;
      padding: 0.5rem 1rem;
    }

    @include mobile-extra-small {
      color: #585151;
      font-size: 18px;
      line-height: 20px;
      background-color: #e5e5e5;
      text-transform: capitalize;
      cursor: none;
      border: none;
      min-width: 180px;
      padding: 0.5rem 1rem;
    }
  }
}
// .disabled {
//   opacity: .5;
// }

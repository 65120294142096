@import '../../assets/styles/styles';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';
@mixin navIcon {
  .nav-open {
    width: 48px;
    height: 48px;
    cursor: pointer;
    z-index: 9999;
    border-radius: 50%;
    margin-right: 0rem;
  }
  .nav-open i {
    display: block;
    width: 20px;
    height: 2px;
    background: $theme-primary-dark;
    border-radius: 2px;
    margin-left: 14px;
  }
  .nav-open i:nth-child(1) {
    margin-top: 16px;
  }
  .nav-open i:nth-child(2) {
    margin-top: 4px;
    opacity: 1;
  }
  .nav-open i:nth-child(3) {
    margin-top: 4px;
  }
}

.headerNew-navLink {
  .desktop-link {
    transition-duration: 300ms;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.spacer {
  height: 4.5rem;

  @media (max-width: 1200px) {
    height: 4rem;
  }
}

.headerNew {
  a {
    text-decoration: none !important;

    &:active {
      text-decoration: none !important;
    }
  }

  background: #e7bfad;
  z-index: 9;
  top: 0;
  position: fixed;
  width: 100%;
  box-shadow: 1px 1px 1px rgba($theme-primary, 0.5);
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 5rem;

  @media (max-width: 1200px) {
    padding: 0.9rem 1.5rem;
    height: 4rem;
  }

  &-logo {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    @include mobile {
      width: 120px;

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }

  &-navLinkMobile {
    display: none;
  }

  .hamburger {
    display: none;
  }

  &-navLink {
    ul {
      display: flex;
      list-style: none;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0;
      gap: 2rem;

      .active {
        font-weight: 600;
      }

      li {
        p {
          color: black;
          font-size: 16px;
        }
        .auth-btn {
          display: flex;
          button {
            min-width: max-content;
            padding: 0.5rem 2rem;
            background-color: $theme-primary-dark;
            color: white;
            border: none;
            @include large-screen {
              padding: 8px 40px;
              font-size: 16px;
            }
            @include large-md-screen {
              padding: 8px 30px;
              font-size: 16px;
            }
          }

          .login {
            margin-right: 1rem;
            color: black;
            background-color: white;
          }
        }
        .user {
          display: flex;
          align-items: center;

          .avatar {
            width: 34px;
            height: 34px;
            background-color: $gray-e;
            border-radius: 100%;
            margin-right: -12px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 100%;
              margin-left: 0rem;
            }
            &-badge {
              position: absolute;
              top: 0;
              right: 0;
              width: 12px !important;
              height: 12px !important;
            }
          }
          .sep {
            p {
              visibility: hidden;
            }
            border-left: 1px solid white;
          }
          p {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

a:active {
  color: black !important;
  background-color: transparent !important;
  text-decoration: underline !important;
} /* selected link */

.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .dropdown-toggle {
    padding: 0rem !important;
    background-color: transparent;
    border: none;
    .link-name {
      display: flex;
      align-items: center;
      p {
        color: black;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
      }
      .active {
        font-weight: 600;
      }
      img {
        width: 15px;
        height: 15px;
        margin-left: 0.5rem;
      }
    }
    &:active,
    &:focus {
      color: $black;
      background-color: transparent;
      box-shadow: none !important;
    }
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    border-radius: 0px;
    border: none;
    margin-top: 0.8rem;
  }
  .dropdown-menu::after {
    position: absolute;
    left: 15%;
    top: -8px;
    width: 0;
    height: 0;
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #ffffff;
    border-radius: 0px;
  }
}

@media (max-width: 1200px) {
  .headerNew {
    &-navLink {
      display: none;
    }
    .navMobHide {
      opacity: 0;
      visibility: hidden;
    }
    .hamburger {
      display: block;
      z-index: 100;
      cursor: pointer;
    }
    .nav-open {
      margin-bottom: 0;
    }
    @include navIcon();

    &-navLinkMobile {
      display: flex;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 5;
      opacity: 1;
      transition-duration: 500ms;
      justify-content: flex-end;

      .logoImg {
        position: absolute;
        top: 0.9rem;
        right: 1.5rem;
        img {
          width: 120px;
        }
      }

      ul {
        z-index: 2;
        display: flex;
        flex-direction: column;
        list-style: none;
        background-color: #e7bfad;
        padding: 6rem 1rem 1rem;
        gap: 2rem;
        margin-bottom: 0;
        overflow-y: auto;

        li {
          font-size: 1rem;
          width: 100%;

          p {
            padding: 0.2rem 0.5rem;
            text-align: left;

            img {
              margin-left: 0.5rem;
            }
          }

          .active {
            background-color: rgba($theme-primary-dark, 0.8);
            color: white;
          }

          .auth-btn {
            display: flex;
            gap: 1rem;

            button {
              padding: 0.3rem 1rem;
              background-color: $theme-primary-dark;
              color: white;
              border: none;

              font-size: 1rem;
            }
            .login {
              color: black;
              background-color: white;
            }
          }
          .user {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .avatar {
              width: 51px;
              height: 51px;
              background-color: $gray-e;
              border-radius: 50%;
              margin-right: 1rem;
              position: relative;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
              &-badge {
                position: absolute;
                top: 0;
                right: 0;
                width: 12px !important;
                height: 12px !important;
              }
            }
            .sep {
              p {
                visibility: hidden;
              }
              border-left: 1px solid white;
            }
            p {
              margin-right: 0rem;
              font-size: 1rem;
              font-weight: 600;

              max-width: 7rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              height: fit-content;
              word-break: break-all;
            }
          }

          .logout {
            margin-top: 3rem;
            font-size: 30px;
            font-weight: 400;
          }
        }
        .extra {
          border-top: 1px solid $theme-primary-dark;
          border-bottom: 1px solid $theme-primary-dark;

          width: 100%;
          li {
            margin-top: 0rem;
          }
          &-second {
            margin-top: 2rem !important;
          }
          padding: 0.5rem 0rem;
        }
      }
    }
  }
}
.homeClass {
  cursor: pointer;
}
.initials {
  font-size: 15px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}

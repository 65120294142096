@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.test {
  left: 17%;
  .modal-dialog {
    min-width: 50% !important;
  }
}

.modal-content-title {
  font-size: 24px;
  line-height: 34px;
  color: #000000;
  margin-bottom: 1rem;

  @include mobile {
    font-size: 18px;
    line-height: 20px;
  }
  @include mobile-extra-small {
    font-size: 16px;
    line-height: 20px;
  }
}
.modal-content-subtitle {
  font-size: 16px;
  line-height: 22px;
  color: #000000;

  @include mobile {
    font-size: 14px;
    line-height: 16px;
  }
  @include mobile-extra-small {
    font-size: 14px;
    line-height: 16px;
  }
}
.modal-content-resendLink {
  font-size: 16px;
  line-height: 22px;
  color: #555353;
  text-align: center;
  &-underline {
    text-decoration: underline;
    cursor: pointer;
  }

  @include mobile {
    font-size: 14px;
    line-height: 16px;
  }
  @include mobile-extra-small {
    font-size: 14px;
    line-height: 16px;
  }
}
.verified {
  color: #68e474;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -2rem;
  margin-left: 1rem;
  @include mobile{
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}
.notVerified {
  color: #f82f2f;
  margin-left: 1rem;
  @include mobile{
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
}
.modal-content-otp {
  width: 100%;
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  &-input:first-child{
    margin-left: 0px;
  }
  &-input {
    border: 2px solid #a5a5a5;
    &:focus {
      outline: none;
    }
    width: 100%;
    height: 80px;
    margin-left: 10px;

    font-size: 72px;
    font-weight: 600;
    text-align: center;

    //width: 75px;
    // margin-right: 0.25rem;

    @include tablet {
      height: 4.8rem;

      font-size: 30px;
      margin-left: 5px;
    }

    @include mobile {
      height: 3rem;
      width: 1.9rem;
      margin-left: 5px;
      font-size: 18px;
    }
    // @include mobile-extra-small {
    //   height: 3.8rem;
    //   width: 1.9rem;
    //   margin-right: 0.14rem;
    // }
  }
  @include mobile{
    margin-top: 0;
    padding: 1rem 0;
  }
  @include mobile-extra-small{
    margin-top: 1rem;
    padding: 1rem 0;
  }
  @include iphone5{
    margin-top: 1rem;
    padding: 1rem 0;
  }

}

.modalPhone {
  height: 100%;
  z-index:1;
  /* opacity: .6; */
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-content1 {
    width: 100%;
    height: 100%;
    position: absolute;
    background: black;
    opacity: .5;
  }
  &-content2 {
    width: 78%;
    background: white;
    height: 76%;
    z-index: 5;
    padding: 3rem 2rem;
    &-set {
      height: 100%;
      .modal-close{
        position: absolute;
        top: -35px;
        cursor: pointer;
        right: -20px;
        @include mobile{
          right: -14px;
        }
        @include iphone5 {
          top: -25px;
          right: -10px;
          height: 10px;
        }
        @include mobile-extra-small{
          top: -25px;
          right: -10px;
        }
      }

      

      .send-otp-button {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 1rem;
        margin: 1rem 0rem;

        @include mobile {
          justify-content: center;
          margin-top: 0;
          margin-bottom: 0;
          padding-right: 0;
        }
        @include mobile-extra-small {
          justify-content: center;
          margin-top: 1rem;
          margin-bottom: 1rem;
          padding-right: 0;
        }

        @include iphone5 {
          justify-content: center;
          margin-top: 1rem;
          margin-bottom: 1rem;
          padding-right: 0;
        }
      }
    }

    @include mobile {
      width: 85%;
      background: white;
      height: 67%;
      z-index: 5;
      padding: 3rem 1.5rem;
    }
    @include mobile-extra-small {
      width: 95%;
      background: white;
      height: 58%;
      z-index: 5;
      padding: 2rem 1rem;
    }

    @include iphone5 {
      width: 78%;
      background: white;
      height: 88%;
      z-index: 5;
      padding: 0.75rem 0.5rem;
    }
  }
}


.green-border-input{
  border: 4px solid #68E474 !important;
}

.red-border-input{
  border: 4px solid #F82F2F !important;
}
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';
.profile {
  //height: 90vh;
  // @media (max-width: 905px) {
  //   height: auto;
  // }
  // min-height: 800px;

  min-height: 300px;

  &-container {
    margin: 0 auto;
    width: 85%;
    @include mobile {
      width: 90%;
    }
    // @media (max-width: 1232px) {
    //   width: 90%;
    // }

    height: 90%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    padding: 0;
    &-heading {
      .heading-title {
        font-size: 36px;
        font-weight: 700;
        @include mobile {
          font-size: 30px;
          display: none;
        }
      }
      .heading-title-mobile {
        font-size: 14px;
        display: none;
        @include mobile {
          display: block;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          color: #43536e;
        }
      }
      .subheading {
        font-size: 14px;
        font-weight: 400;
        @include mobile {
          font-size: 12px;
          text-align: center;
          margin-top: 0.5rem;
          width: 100%;
          display: none;
        }
      }
      // @media (max-width: 714px) {
      //   h1 {
      //     font-size: 2rem;
      //   }
      // }

      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include mobile{
        align-items: center;
      }

      // @media (max-width: 905px) {
      //   flex-direction: column;
      //   align-items: center;
      // }
      p {
        margin-bottom: 0.5rem;

        span {
          color: red;
          margin-left: 10px;
        }
      }
    }

    &-fields {
      display: flex;
      height: 100%;

      padding-top: 2rem;

      // @media (max-width: 905px) {
      //   flex-direction: column;
      // }

      @include tablet {
        flex-direction: column;
      }
      @include mobile {
        flex-direction: column;
        padding-top: 1rem;
      }

      @media screen and (min-width: 641px) and (max-width: 768px) {
        flex-direction: column;
      }

      &-photoContainer {
        flex: 1;
        min-width: 300px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        @include mobile {
          min-width: 150px;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
        }
        @include tablet {
          align-items: center;
        }

        @media screen and (min-width: 641px) and (max-width: 768px) {
          min-width: 150px;
          justify-content: center;
          align-items: center;
        }

        &-imageBox {
          width: 280px;
          height: 280px;
          @include mobile {
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $gray-photo-back;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            @include mobile {
              border-radius: 50% !important;
            }
          }
          // @media (max-width: 905px) {
          //   border-radius: 100%;
          //   width: 150px;
          //   height: 150px;
          // }
        }
        &-edit {
          display: none;
          @include mobile {
            display: block;
            position: absolute;
            bottom: -9px;
            right: 6px;
          }
        }
        &-editControls {
          .editTitle {
            font-weight: 400;
            font-size: 17px;
            line-height: 23px;
            color: #43536e;
            text-align: left;
            margin-left: 15px;
            padding-bottom: 1rem;
          }
          .editOptions {
            display: flex;
            padding-bottom: 1.5rem;
          }
          .editItems {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 15px;
          }
          span {
            font-size: 10px;
            color: #9d9d9d;
            font-weight: 400;
            line-height: 14px;
          }
        }
        &-control {
          width: 280px;
          @include mobile {
            width: 100%;
          }

          @media screen and (min-width: 641px) and (max-width: 768px) {
            width: 100%;
          }

          p {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            font-size: 14px;
            font-weight: 400;

            label {
              &:hover {
                cursor: pointer;
              }
            }

            .control {
              font-size: 0.8rem;
            }
          }
          .addedImage {
            justify-content: space-between;
            @include mobile {
              display: none;
            }
          }

          .ctr-del {
            color: black;
            opacity: 0.5;
          }
          .upload {
            @include mobile {
              display: none;
            }
          }
        }
        .membershipBox {
          width: 280px;
          @include mobile {
            width: 100%;
          }
          margin-top: 3rem;

          @include tablet {
            width: 100%;
          }

          @media screen and (min-width: 641px) and (max-width: 768px) {
            width: 100%;
          }

          display: flex;
          flex-direction: column;
          border: 1px solid $theme-red-light;
          position: relative;
          border-radius: 10px;

          .text {
            font-size: 24px;
            font-weight: 300;
            color: $theme-red;
            margin: 5px 0;
          }

          .text-2 {
            font-size: 14px;
            font-weight: 400;
            opacity: 0.6;
          }

          .upper {
            display: flex;
            padding-left: 1rem;
            padding-top: 0.8rem;

            &-left {
              flex: 2;
              text-align: start;
              margin-top: 1rem;
              .date {
                font-size: 16px;
                font-weight: 600;
                opacity: 1;
              }
            }
            &-right {
              flex: 1;

              margin-left: 1.5rem;
            }
          }
          .lower {
            @include tablet {
              margin-top: 1rem;
            }

            text-align: start;
            padding: 1rem;
            border-top: 1px solid $theme-red-light;

            .dot-container {
              display: grid;
              grid-template-columns: repeat(6, 1fr);
              place-items: center;
              margin-top: 1rem;
              position: relative;

              .dot {
                margin-top: 0;
                padding: 2px;

                &.active {
                  border: 1px solid #d68477;

                  div {
                    background-color: #d68477;
                  }
                }

                div {
                  height: 10px;
                  width: 10px;
                  border-radius: 50%;
                  background-color: #777672;
                }
              }

              .line {
                height: 2px;
                width: 100%;
                background-color: #777672;
                grid-column: 1/6;
                position: absolute;
                bottom: 37%;
                left: 10%;
                opacity: 0.3;
              }
            }
          }
          .ribbon {
            font-size: 16px;
            font-weight: 600;
            background-color: #f7e6e4;
            border-radius: 5px;
            padding: 0.5rem 0.5rem;
            position: absolute;
            top: -15%;
            left: -1px;

            p {
              color: #d68477;
            }
          }
        }
      }

      &-form {
        flex: 3;
        margin-top: -0.5rem;

        // @media (max-width: 1037px) and (min-width: 905px) {
        //   margin-left: -50px;
        // }

        min-width: 300px;

        @include mobile {
          min-width: 150px;
          padding-top: 2rem;
        }

        @media screen and (min-width: 641px) and (max-width: 768px) {
          min-width: unset;
        }
        flex-direction: column;
        padding: 0rem;

        // @media (max-width: 905px) {
        //   width: 100%;
        //   align-self: center;
        //   margin-top: 1rem;
        // }

        &-input {
          // height: 310px !important;
          width: 90%;

          @include tablet {
            height: auto !important;
            width: 100%;
          }
          @include mobile {
            height: auto !important;
            width: 100%;
          }

          @media screen and (min-width: 641px) and (max-width: 768px) {
            height: auto !important;
            width: 100%;
          }

          .inputfield {
            @media screen and (min-width: 641px) and (max-width: 768px) {
              width: 100%;

              input {
                width: 100%;
              }
            }
            .error {
              color: red;
              margin-top: 8px;
              font-size: 14px;
              @include mobile{
                display: none;
              }
            }
            &-error-mobile{
              display: none;
              @include mobile{
                display: block;
                color: red;
              }
            }
          }

          // @media (max-width: 1037px) {
          //   width: 100%;
          // }
          // @media (max-width: 905px) {
          //   justify-content: center;
          // }

          display: flex;
          flex-wrap: wrap;
          flex: 4;
          min-height: 200px;

          // &-datePicker {
          //   // @media (max-width: 905px) {
          //   //   margin-top: 2rem;
          //   //   width: 100%;
          //   //   margin-left: 0rem;
          //   // }
          //   label {
          //     font-size: 18px;
          //     font-weight: 400;
          //   }
          //   @include tablet {
          //     width: 100%;
          //     margin-left: 0rem;
          //     margin-top: 2rem;
          //   }
          //   @include mobile {
          //     width: 100%;
          //     margin-left: 0rem;
          //     margin-top: 2rem;
          //   }
          //   @include large-screen {
          //     margin-left: 2rem;
          //     margin-top: 0.5rem;
          //   }
          //   @include window-desktop-custom {
          //     margin-left: 1rem;
          //     // margin-top: .5rem;
          //   }

          //   @media screen and (min-width: 641px) and (max-width: 768px) {
          //     width: 100%;
          //   }
          //   display: flex;
          //   flex-direction: column;
          //   align-items: flex-start;
          //   margin-left: 2rem;
          //   margin-top: 0.5rem;
          //   small {
          //     color: red;
          //     font-size: 14px;
          //     font-weight: 700;
          //     @include mobile {
          //       font-size: 10px;
          //     }
          //   }

          //   .datepicker-wrap {
          //     border: 1px solid $gray-light;
          //     position: relative;
          //     @include tablet {
          //       width: 100%;
          //     }
          //     @include mobile {
          //       width: 100%;
          //     }
          //     @media screen and (min-width: 641px) and (max-width: 768px) {
          //       width: 100%;
          //     }
          //   }
          //   .datepicker-error {
          //     border: 1px solid red;
          //   }

          //   .react-datepicker {
          //     margin-left: 100px;
          //     @include mobile {
          //       margin-left: 0px;
          //     }
          //     @include tablet {
          //       margin-left: 0px;
          //     }
          //   }

          //   .react-datepicker-wrapper {
          //     // @media (max-width: 905px) {
          //     //   width: 100%;
          //     //   height: 50px;
          //     // }
          //     @include tablet {
          //       width: 100%;
          //     }
          //     @include mobile {
          //       width: 100%;
          //     }

          //     .react-datepicker__input-container {
          //       input {
          //         border: none;
          //         width: 317px;
          //         font-size: 18px;
          //         @media (max-width: 1157px) {
          //           width: 250px;
          //         }

          //         // @media (max-width: 905px) {
          //         //   width: 100%;
          //         //   height: 50px;
          //         // }
          //         @include tablet {
          //           width: 100%;
          //         }
          //         @include mobile {
          //           width: 100%;
          //         }

          //         height: 44px;
          //         padding-left: 1rem;
          //       }

          //       .react-datepicker__close-icon {
          //         right: 10%;
          //       }
          //     }
          //   }
          //   &-calendarIcon {
          //     cursor: pointer;
          //     // display: none;
          //     width: 18px;
          //     height: 20px;
          //     position: absolute;
          //     top: 50%;
          //     right: 2%;
          //     transform: translateY(-50%);
          //   }
          // }

          .date-input {
            width: 317px;

            @include mobile {
              width: 69%;
            }
            @include tablet {
              width: 100%;
            }

            .MuiInput-underline:hover:not(.Mui-disabled):before,
            .MuiInput-underline:before,
            .MuiInput-underline:after {
              border-bottom: 0;
            }
            .MuiFormControl-root {
              width: 100%;
              @include mobile {
                align-items: flex-end;
              }
            }
            .MuiInputBase-root {
              border-radius: 0;
              border: 1px solid #c4c4c4;
              @include mobile {
                border: 1px solid transparent;
              }
            }
            .MuiFormHelperText-root.Mui-error {
              display: none;
            }

            input {
              border: 0;
            }
          }
        }

        &-aoc {
          margin-top: 4rem;
          width: 100%;
          padding: 0.75rem 0rem;

          // min-height: 150px;

          display: flex;
          border: 1px solid #a5a5a5;
          flex-direction: column;
          margin-left: 2rem;

          @media screen and (min-width: 641px) and (max-width: 768px) {
            width: unset;
          }

          @include mobile {
            margin-top: 2rem;
            margin-left: 0rem;
            border: none;
          }
          @include window-desktop-custom {
            margin-left: 1rem;
          }
          // @media (max-width: 574px) {
          //   padding: 0rem;
          // }
          // @media (max-width: 905px) {
          //   margin-top: 3rem;
          // }
          @include tablet {
            margin-top: 4rem;
            margin-left: 0rem;
          }
          display: flex;
          .carousel-placeholder {
            text-align: left;
            font-size: 18px;
            font-weight: normal;
            padding-left: 1.5rem;
            margin-bottom: 1rem;
            @include mobile {
              font-size: 18px;
              padding-left: 0rem;
            }
          }
          //justify-content: space-between;
          .slide {
            // @media (max-width: 905px) {
            //   width: 80%;
            //   margin: 0 auto;
            // }

            .wrap {
              padding: 0.5rem 0rem 0.5rem 27px;
              @include tablet {
                padding: 0.5rem 0rem 0.5rem 27px;
              }
              @media (max-width: 778px) {
                padding: 0.5rem 0rem 0.5rem 20px;
              }
            }

            .slick-prev {
              top: 50%;
              left: -3.5%;
              @include window-desktop {
                left: -4%;
              }
              @include tablet {
                left: -5.4%;
              }
            }
            .slick-next {
              top: 50%;
              right: -1%;
            }
            .slick-arrow {
              margin-top: -25px;
            }

            .slick-next,
            .slick-prev {
              @include tablet {
                display: none !important;
              }
              @include mobile {
                display: none !important;
              }
            }
            .slick-prev:before,
            .slick-next:before {
              content: '' !important;
            }

            .slick-list {
              .slick-track {
                margin-left: 16px;
              }
            }
          }

          &-mobile {
            display: flex;
            min-width: 300px;
            overflow-x: auto;
            z-index: 0 !important;
          }
        }

        &-submit {
          width: 100%;
          min-height: 100px;

          margin-left: 2rem;

          display: flex;
          justify-content: flex-end;
          @include mobile {
            flex-direction: column;
          }
          // @media (max-width: 905px) {
          //   margin-top: 2rem;

          //   justify-content: center;
          // }
          @include tablet {
            margin-top: 2rem;
            margin-left: 0rem;
            justify-content: center;
          }
          @include mobile {
            margin-top: -2rem;
            margin-left: 0rem;
            justify-content: center;
          }
          &-btn {
            margin-top: 2rem;
            @include button-primary;
            @include mobile {
              width: 100%;
            }
          }
          &-todash {
            margin-left: 10px;
            @include mobile {
              margin-left: 0px;
            }
          }

          .save-active {
            background-color: $theme-primary-dark;
            color: white;
          }
        }
      }
    }
  }
}

.errorEmail {
  height: 24px;
  width: 100%;
  margin-left: 2rem;
  text-align: end;
  margin-top: 0.5rem;
  color: #dc3545;
}

.toast {
  min-width: 280px;
  .toast-body {
    text-align: left;
    padding: 16px;

    &-content {
      line-height: 22px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .toast-close {
      height: 18px;
      width: 18px;
    }
  }
}

@import '../../assets/styles/styles.scss';

.error-wrapper {
  overflow-x: hidden;

  .webinar-section {
    padding-top: 78px;
  }

  .error-section {
    padding: 60px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include mobile {
      padding: 50px 30px;
    }
  }
}
.page-supportGroup {
  .group {
    @include mobile {
      background-color: $theme-primary-dark;
    }
    .nav-name {
      font-weight: 700;
      border-bottom: 2px solid $black;
      @include mobile {
        color: $white;
        border-bottom: 0px;
      }
    }
  }
}
.button {
  &-primary {
    color: $white;
    height: 50px;
    font-size: 16px;
    min-width: 220px;
    font-weight: 600;
    line-height: 20px;
    border: none;
    padding: 0.5rem 1rem;
    text-transform: capitalize;
    background-color: $theme-blue-primary;

    .button-redirect{
      text-decoration: none !important;
    }
    @include window-desktop {
      font-size: 18px;
      line-height: 20px;
    }
    @include mobile {
      color: $white;
      font-size: 18px;
      min-width: 180px;
      font-weight: 600;
      line-height: 20px;
      height: 36px;
      border: none;
      padding: 0.5rem 1rem;
      text-transform: capitalize;
      background-color: $theme-blue-primary;
    }
    @include mobile-extra-small {
      color: $white;
      font-size: 16px;
      min-width: 180px;
      font-weight: 600;
      height: 36px;
      line-height: 18px;
      border: none;
      padding: 0.5rem 1rem;
      text-transform: capitalize;
      background-color: $theme-blue-primary;
    }
  }
}
// .btn-homepage {
//   height: 42px;
//   max-width: 100%;
//   color: $white;
//   background-color: $theme-primary-dark;
//   margin-top: 10px;

//   @include mobile {
//     width: 100% !important;
//   }

//   &:hover,
//   &:focus,
//   &:active {
//     background-color: $theme-primary-dark;
//     color: $white;
//     font-weight: 600;
//     transition: all 0.2s ease;
//     box-shadow: none;
//   }
// }

@import '../../assets/styles/styles.scss';

.footer {
  margin-top: 52px;
  padding: 32px 170px;
  background-color: #f5ede5;

  @include tablet {
    padding: 32px 32px;
  }

  @include mobile {
    padding: 32px 32px;
  }

  .img {
    height: 28px;
  }

  &-head {
    display: flex;
    align-items: center;
    padding-bottom: 26px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 0px;
    }

    .social-links {
      margin-left: 20px;

      @include mobile {
        margin-left: 0px;
        margin-top: 34px;
        margin-bottom: 34px;
      }
    }

    a {
      margin-left: 28px;

      @include mobile {
        &:first-child {
          margin-left: 0px;
        }
      }
    }
  }

  &-content {
    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;

      @include mobile {
        &:nth-last-child(-n + 2) {
          padding-top: 38px;
        }
      }
    }
  }

  &-heading {
    font-size: 24px;
    color: #d3776e;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
  }

  &-text {
    font-size: 16px;
    margin-top: 12px;
    color: #47546d;

    a {
      color: #47546d;
    }
  }
}

.footer_contact {
  height: 24px;
  width: 24px;
  background-color: #122839;
  border-radius: 100%;
}
.image_contact_whatsapp {
  height: 28px;
  width: 28px;
}
.image_contact {
  height: 18px;
  width: 18px;
  padding: 2px;
}
.contact_text {
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #a5a5a5;
}
.linkToWhatsapp {
  color: $gray-a;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin-top: 20px;

  @include mobile {
    text-align: center;
  }

  &:hover,
  &:active,
  &:focus {
    color: $gray-a;
    text-decoration: none;
  }
}

@import '../../assets/styles/variables';
@import '../../assets//styles/mixins';

.dropdown {
    .input-field {
        width: 100%;
        height: 60px;
        font-size: 30px;
        font-weight: 400;
        line-height: 20px;
        padding: 8px;
        border: 1px solid #a5a5a5;
    
        @include window-desktop {
          height: 60px;
          font-size: 22px; 
        }
        @include tablet{
        height: 50px;
        font-size: 18px;
        }
        @include mobile{
        height: 40px;
        font-size: 18px;
        }
        @include iphone5 {
          height: 36px;
          font-size: 12px;
        }
        @include mobile-extra-small {
          height: 32px;
          font-size: 12px;
          padding: 4px 8px;
        }
      }
    &-data{
        // height: 35vh;
        height: auto;
        max-height:215px;
        position: absolute;
        top: 60px;
        z-index: 0;
        width: 23vw;
        background-color: #f4e1d7;
        border-radius: 10px;
        overflow: auto;
        // overflow-x: scroll;

        @include tablet{
            width: 30vw;
        }
        @include mobile{
            top: 40px;
        }
        @include mobile-extra-small{
            top: 40px;
        }

        ul{
            padding: 0px;
            text-align: left;
        }
        ul > li {
            list-style-type: none;
            margin: .5rem 0;
            padding-bottom: 5px;
            border-bottom: .1px solid rgb(153, 153, 153);
            border-bottom: .1px solid rgba(153, 153, 153, .2);
            -webkit-background-clip: padding-box; /* for Safari */
            background-clip: padding-box;
        }
        .list-wrapper{

            padding: 1rem .75rem;
        }
        .list-data {
            display: flex;
            align-items: center;

            .code{
                min-width: 61px;
                max-width: 61px;
                margin-right: 10px;

                @include large-screen {
                    min-width: 80px;
                // max-width: 61px;
                }
            }

            
        }

        @include tablet {
            width: 22vw;
        }
        @include mobile{
            width: 60vw;
        }

        @include mobile-extra-small{
            width: 60vw;
            height: 30vh;
        }
    }
}
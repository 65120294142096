@import './variables';
@import './mixins';
@import './fonts';

html {
  @include large-screen {
    font-size: 20px !important;
  }

  @include iphone5 {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  color: $black;

  @include large-screen {
    font-size: 1.25rem;
  }
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.fontTenor {
  font-family: 'Tenor Sans', sans-serif;
}

button,
input {
  &:focus {
    outline: none;
  }
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: transparent !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}

a {
  color: $black;

  &:hover {
    color: $black;
  }

  &:focus {
    color: $black;
    outline: none;
    text-decoration: underline;
  }
}

p {
  margin-bottom: 0px;
}

.pt-20 {
  padding-top: 20px;
}

.themeDark {
  color: #43536e;
}

.textCenter {
  text-align: center;
}

.mt-20 {
  margin-top: 20px;
}

.mt-0 {
  margin-top: 0px;
}

.items-center {
  align-items: center;
}

.btn-darks {
  height: 42px;
  max-width: 100%;
  color: $white;
  background-color: $theme-primary-dark;
  margin-top: 10px;

  @include mobile {
    width: 100% !important;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $theme-primary-dark;
    color: $white;
    font-weight: 600;
    transition: all 0.2s ease;
    box-shadow: none;
  }
}

.no-line {
  text-decoration: none !important;
}

.p-r-55 {
  padding-right: 55px;

  @include mobile {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  p {
    color: $gray-a;
    font-size: 30px;
  }
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 47%;
  z-index: 1;
}

.dot {
  display: inline-block;
  vertical-align: middle;
  margin-top: 2rem;
  padding: 0;
  border-radius: 40px;
  width: 100%;
  text-align: center;
  li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    padding: 0;
    button {
      width: 15px;
      height: 15px;
      border: 1px solid black;
      border-radius: 100%;
      font-size: 0px;
      background-color: transparent;
    }
  }

  .pagging {
    width: 15px;
    height: 15px;
    border: 1px solid black;
    border-radius: 100%;
  }
  .slick-active {
    button {
      background-color: $gray-medium;
      border: 1px solid transparent;
    }
    .pagging {
      background-color: $gray-medium;
      border: 1px solid transparent;
    }
  }
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 1002;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.not-rounded {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  // @include mobile {
  //   border-top-left-radius: 0px;
  //   border-bottom-left-radius: 0px;
  //   position: fixed;

  //   .spinner {
  //     position: static;
  //   }
  // }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid $peach-secondary;
  border-right: 6px solid $peach-secondary;
  border-bottom: 6px solid $peach-secondary;
  border-top: 6px solid $peach-primary;
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';
.inputfield {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 0.5rem;
  @include mobile {
    width: 100%;
    border-bottom: 1px solid rgba(157, 157, 157, 0.3);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  // @media (max-width: 905px) {
  //   width: 100%;
  //   margin-top: 2rem;
  //   margin-left: 0rem;
  // }
  @include tablet {
    width: 100%;
    margin-left: 0rem;
    margin-top: 2rem;
  }

  // @include window-desktop-custom {
  //   margin-left: 1rem;
  //   // margin-top: 2rem;
  // }
  height: min-content;
  align-items: flex-start;
  margin-left: 2rem;

  @include mobile {
    margin-left: 0rem;
  }

  @include window-desktop-custom {
    margin-left: 1rem;
  }

  position: relative;

  label {
    font-size: 18px;
    font-weight: 400;
    @include mobile {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.25px;
      color: #43536e;
      display: flex;
    }
    span {
      color: red;
      font-size: 18px;
      font-weight: 400;
    }
  }
  &-error {
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
    @include mobile {
      font-size: 10px;
      display: none;
    }
    &-mobile{
      display: none;
      @include mobile{
        display: block;
        color: red;
        text-align: right;
        width: 100%;
      }
    }
  }
  

  input {
    width: 317px;
    font-size: 18px;
    @include mobile {
      width: 70%;
      padding: 0rem 0rem 0rem 0.5rem;
      text-align: right;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #43536e;
    }
    // @media (max-width: 1157px) {
    //   width: 250px;
    // }
    // @media (max-width: 1037px) {
    //   width: 250px;
    // }

    // @media (max-width: 905px) {
    //   width: 100%;
    //   height: 50px;
    // }
    @include tablet {
      width: 100%;
    }

    min-width: 100px;
    height: 44px;
    border: 1px solid $gray-light;
    padding: 0rem 3rem 0rem 1rem;
  }
  .input-error {
    border: 1px solid red;
  }

  @include mobile {
    .input-loginpage {
      border: 1px solid transparent;
    }
  }

  &-tick {
    position: absolute;
    top: 60%;
    right: 5%;
    @include mobile {
      display: none;
    }
  }
  .bottom-subText {
    position: absolute;
    top: 105%;
    left: 0;
    width: 325px;
    @include tablet {
      width: 100%;
    }
    @include mobile {
      width: 100%;
    }
    font-size: 10px;
    display: flex;
    text-align: start;
    span {
      width: 15px;
      input[type="checkbox"] {
        width: 0px;
        height: 0px;

        cursor: pointer;
      }
      input[type="checkbox"]:before {
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;

        @media (max-width: 626px) {
          width: 20px;
          height: 20px;
        }
        top: 0px;
        left: 0;
        border: 1px solid #a5a5a5;

        background-color: white;
      }
      input[type="checkbox"]:checked:after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        @media (max-width: 626px) {
          width: 5px;
          height: 10px;
        }
        border: solid #6bbea6;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 6px;
      }
    }
    p {
      font-size: 10px;
      margin-left: 3px;
      @include mobile {
        font-size: 8px;
        margin-left: 10px;
      }
    }
  }
}

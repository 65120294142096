@import '../../assets/styles/styles.scss';

.desktop {
  @include mobile {
    display: none;
  }
  transition: all 2s linear;
}

.mobile {
  display: none;

  @include mobile {
    display: block;
  }

  .whatsapp {
    padding: 10px;
  }
}

.whatsapp {
  padding: 8px 16px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 2;
  border: 3px solid $theme-primary-dark;
  border-radius: 3rem;
  background-color: $white;

  display: flex;
  align-items: center;

  cursor: pointer;

  .text {
    font-size: 18px;
    margin-right: 5px;
    user-select: none;
    font-weight: 600;
  }

  .img {
    height: 30px;
    -webkit-user-drag: none;
  }
}

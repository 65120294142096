@import '../../assets/styles/mixins';

@import '../../assets/styles/variables';
@import '../../assets/styles/styles.scss';
p {
  color: $theme-primary-dark;
}

.image-arrow {
  margin: 0rem 0rem 0rem 12px;
}
.rev {
  transform: rotate(180deg);
}
.mob-li-name {
  color: white !important;
  background-color: $theme-primary-dark;
  padding-top: 1rem;
}

.service-drop {
  transition: all 0.5s ease;
  // background-color: $theme-primary-dark;
  width: 100%;

  ul {
    margin-top: 0rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;
    gap: 0.5rem;
    text-align: left;

    .linkp {
      color: rgba(67, 83, 110, 0.75);
      font-size: 16px;
      font-weight: 400;
      padding: 0.5rem 0rem;
    }

    li {
      margin-top: 0rem;
      padding: 0.5rem 0rem;
      flex: 1;
      font-size: 16px;
      font-weight: 400;
      color: rgba(67, 83, 110, 0.75);
    }

    hr {
      width: 100%;
      border-top: 1px solid $gray-a;
      margin: 0;
    }
    &:last-child {
      padding-bottom: 1rem !important;
    }
  }
}

@mixin mobile {
  @media only screen and (min-width: 320px) and (max-width: 640px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin large-custom-desktop {
  @media only screen and (min-width: 1400px) and (max-width: 1498px) {
    @content;
  }
}



@mixin large-screen {
  @media screen and (min-width: 1500px) {
    @content;
  }
}

@mixin large-md-screen {
  @media screen and (min-width: 1500px) and (max-width: 1920px) {
    @content;
  }
}

@mixin iphone5 {
  @media screen and (min-width: 321px) and (max-width: 359px) {
    @content;
  }
}

@mixin mobile-extra-small {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

@mixin window-desktop {
  @media only screen and (min-width: 1023px) and (max-width: 1200px) {
    @content;
  }
}

@mixin button-primary {
  height: 40px;
  width: 200px;
  border: none;
  color: $gray-medium;
  background-color: $gray-light;
  font-size: 0.8rem;
}

@mixin window-desktop-custom {
  @media only screen and (min-width: 1201px) and (max-width: 1299px) {
    @content;
  }
}
